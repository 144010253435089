@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
@font-face {
  font-family: "indira_kregular";
  src: url(/static/media/indira_k-webfont.ecbdc955.woff2) format("woff2"), url(/static/media/indira_k-webfont.6b6d4612.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

.py-5 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.container {
  max-width: 1292px !important; }

body {
  height: 100%;
  min-width: 320px; }

.no-border {
  border-radius: 0 !important; }

@media only screen and (max-width: 768px) {
  .row {
    width: 97% !important; }
  .container {
    padding: 0 !important; } }

.Loading .loading {
  width: 100%;
  height: 100%;
  min-height: 908px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center; }

.Loading .lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.Loading .lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.Loading .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

.SiteHeading .helvar-merca-profi {
  color: #ffffff;
  font-family: "indira_kregular";
  font-size: 33px;
  line-height: 36px;
  padding: 32px 36px;
  z-index: 0; }

.SiteHeading .rectangle-copy {
  max-width: 408px;
  background-color: #A0243E;
  margin-left: 5px; }

.SiteHeading .helvar-merca-profi p {
  margin-bottom: 0 !important; }

@media (max-width: 767.98px) {
  .SiteHeading {
    background: none !important; }
    .SiteHeading .heading-container {
      width: 207px; }
    .SiteHeading .rectangle-copy {
      height: auto;
      margin-left: 0 !important;
      word-break: break-word; }
    .SiteHeading .helvar-merca-profi {
      padding: 10.9px 34.8px; }
    .SiteHeading .helvar-merca-profi p {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.5px; }
    .SiteHeading .heading-container,
    .SiteHeading .mobile-col {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

.ContactCard .body-text-contact a,
.ContactCard .body-text-contact a:hover {
  color: #A0243E;
  text-decoration: underline; }

.ContactCard .contact-container {
  padding: 0px 33px !important;
  font-size: 12px !important;
  font-family: "PT Sans"; }

.ContactCard .contact-img-container {
  margin-bottom: 20px; }

.ContactCard .contact-img {
  width: 83pt;
  height: 83pt;
  position: absolute;
  bottom: 25px;
  right: 0; }

.ContactCard .body-text-contact {
  margin-top: -5.9px;
  margin-bottom: 18.3px;
  font-family: "PT Sans"; }

.ContactCard .body-text-contact p {
  color: #000;
  font-size: 16pt;
  margin: 0 !important;
  letter-spacing: 0px;
  line-height: 24.8px; }

.ContactCard .body-text-contact-heading {
  font-weight: bold;
  font-size: 18pt;
  letter-spacing: -0.3px;
  line-height: 15px;
  color: #222222; }

@media (max-width: 767.98px) {
  .ContactCard .contact-img {
    right: -24px;
    bottom: 24px;
    width: 63pt;
    height: 63pt; }
  .ContactCard .contact-container {
    padding: 0px 21px !important; }
  .ContactCard .body-text-contact-heading {
    font-weight: bold;
    font-size: 13.9px;
    letter-spacing: 0px;
    line-height: 14.3px; }
  .ContactCard .body-text-contact {
    margin-top: -15px; }
  .ContactCard .body-text-contact p {
    color: #222222;
    font-size: 13.9px;
    margin: 0 !important;
    letter-spacing: -0.4px;
    line-height: 16px; }
  .ContactCard .btn-maroon {
    padding: 5.3px 9px; }
  .ContactCard .body-text-copyright {
    font-size: 10.8px;
    line-height: 9.8px;
    padding: 0 20.5px 26.4px;
    letter-spacing: -0.5px; } }

.Language .language-container {
  background: #fff;
  font-family: "PT Sans";
  font-weight: bold;
  color: #a0a0a0;
  margin-left: 5px;
  position: fixed;
  padding-left: 7px;
  padding-right: 7px;
  height: auto;
  width: auto;
  z-index: 1; }

.Language .language-container a {
  color: #A0A0A0 !important;
  text-decoration: none; }

.Language .language-container a.active {
  color: #222222 !important;
  text-decoration: none; }

.Language .btn-link {
  float: left;
  padding: 5px 11px;
  font-weight: bold; }

.Language .btn-maroon {
  color: #000 !important;
  border: none !important; }

@media (max-width: 767.98px) {
  .Language .language-container {
    margin-left: 11.8px; }
  .Language .btn-link {
    font-size: 11.5px;
    padding: 5px 7px; } }

.SiteTitle {
  box-sizing: border-box; }
  .SiteTitle h1 {
    font-family: "indira_kregular";
    font-size: 33.3px !important;
    color: #fff;
    letter-spacing: 0.4px;
    line-height: 25.6px !important;
    padding-top: 49px;
    padding-bottom: 6.1px; }
  .SiteTitle h1 img {
    max-width: 63%;
    top: -7px;
    left: 15px;
    position: absolute; }
  .SiteTitle .logo-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    background: #A0243E;
    text-align: left;
    width: 413.3px;
    padding-left: 26.6px;
    z-index: 1; }
  @media (max-width: 767.98px) {
    .SiteTitle .sitetitle {
      padding: 0 !important;
      float: right;
      display: block;
      top: 0;
      position: absolute !important; }
    .SiteTitle .logo-container {
      width: 41.8vw;
      padding-left: 14px; }
    .SiteTitle .logo-container h1 {
      font-size: 20.1px !important;
      letter-spacing: -0.1px;
      margin-bottom: 1px !important;
      line-height: 23.8px !important;
      padding-bottom: 5%;
      padding-top: 21%; }
    .SiteTitle .logo-container h1 img {
      max-width: 97%;
      top: -2px;
      left: 7px;
      position: absolute; } }

.Desktop .mt-6 {
  margin-top: 13.8rem !important; }

@media (max-width: 767.98px) {
  .Desktop {
    display: none !important; }
    .Desktop .mt-6 {
      margin-top: 13.8rem !important; } }

.SiteContent .scrim {
  width: 568px;
  background-color: #ffffff;
  margin-left: 4px;
  padding-bottom: 20px; }

.SiteContent .body-text {
  color: #222222;
  font-family: "PT Sans";
  padding: 21.3px 47.8px 0;
  margin-bottom: 0;
  width: 100%; }

.SiteContent .body-text p {
  letter-spacing: -0.6px;
  font-size: 16pt;
  text-align: left;
  line-height: 24.7px;
  padding-bottom: 0px;
  margin-bottom: 25px;
  -webkit-font-smoothing: subpixel-antialiased; }

.SiteContent .body-text a,
.SiteContent .body-text a:hover {
  color: #A0243E;
  text-decoration: underline; }

.SiteContent .body-text-copyright {
  color: #a0a0a0;
  font-size: 13pt;
  line-height: 12.1px;
  padding: 0px 34px 10px;
  letter-spacing: -0.5px; }

.SiteContent .body-legal-notice {
  font-size: 13pt;
  line-height: 12.1px;
  padding: 0px 34px 26.4px;
  letter-spacing: -0.5px; }

@media (max-width: 767.98px) {
  .SiteContent .scrim {
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
    margin-bottom: 0 !important; }
  .SiteContent .body-text {
    width: 100% !important;
    padding-left: 35px;
    padding-right: 0px;
    padding-top: 17.1px; }
  .SiteContent .body-text p {
    letter-spacing: 0.4px;
    font-size: 15.1px !important;
    text-align: left;
    line-height: 18.9px;
    padding-bottom: 0px;
    margin-bottom: 18.6px;
    -webkit-font-smoothing: subpixel-antialiased; }
  .SiteContent .body-text-copyright {
    font-size: 10.8px;
    padding: 0 20px 5px;
    letter-spacing: -0.5px; }
  .SiteContent .body-legal-notice {
    margin-bottom: 10px !important;
    font-size: 10.8px;
    line-height: 9.8px;
    padding: 0 20px 20px;
    letter-spacing: -0.5px; } }

.Mobile {
  display: none; }
  .Mobile .mt-6 {
    margin-top: 13.8rem !important; }
  @media (max-width: 767.98px) {
    .Mobile {
      margin-bottom: 10px;
      display: inline-block; }
      .Mobile .mobile-container {
        width: 100%; }
      .Mobile .mt-6 {
        margin-top: 13.8rem !important; } }

