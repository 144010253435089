@import "all";

.SiteTitle {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  h1 {
    font-family: $font-heading;
    font-size: 33.3px !important;
    color: #fff;
    letter-spacing: 0.4px;
    line-height: 25.6px !important;
    padding-top: 49px;
    padding-bottom: 6.1px;
  }
  h1 img {
    max-width: 63%;
    top: -7px;
    left: 15px;
    position: absolute;
  }

  .logo-container {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    background: $base-color;
    text-align: left;
    width: 413.3px;
    padding-left: 26.6px;
    z-index: 1;
  }

  @include media-breakpoint-down(sm) {
    .sitetitle {
      padding: 0 !important;
      float: right;
      display: block;
      top: 0;
      position: absolute !important;
    }
    .logo-container {
      width: 41.8vw;
      padding-left: 14px;
    }
    .logo-container h1 {
      font-size: 20.1px !important;
      letter-spacing: -0.1px;
      margin-bottom: 1px !important;
      line-height: 23.8px !important;
      padding-bottom: 5%;
      padding-top: 21%;
    }
    .logo-container h1 img {
      max-width: 97%;
      top: -2px;
      left: 7px;
      position: absolute;
    }
  }
}
