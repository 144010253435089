@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");
@font-face {
  font-family: "indira_kregular";
  src: url("../../assets/fonts/indira_k-webfont.woff2") format("woff2"),
    url("../../assets/fonts/indira_k-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.py-5 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.container {
  max-width: 1292px !important;
}

body {
  height: 100%;
  min-width: 320px;
}
.no-border {
  border-radius: 0 !important;
}

@media only screen and (max-width: 768px) {
  .row {
    width: 97% !important;
  }
  .container{
    padding:0 !important;
  }
}
