@import "all";

.Desktop {
  .mt-6 {
    margin-top: 13.8rem !important;
  }

  @include media-breakpoint-down(sm) {
    display: none !important;

    .mt-6 {
      margin-top: 13.8rem !important;
    }
  }
}
