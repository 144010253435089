@import 'all';

.Mobile {
  display: none;

  .mt-6 {
    margin-top: 13.8rem !important;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
    display: inline-block;

    .mobile-container {
      width: 100%;
    }

    .mt-6 {
      margin-top: 13.8rem !important;
    }
  }
}
